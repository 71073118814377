<script>

import {
    getReason1688,
    getRefundType, getRefundType2, manyRefund,
    refundCount,
    refundDetail,
    refundIndexList,
    refundOrder,
    refundSyn, synDisOrder, taoTeRefund3,
} from "@/api/department";
import lbb from "@/assets/pages/1688.png";
import TT from "@/assets/pages/TT.jpg";

export default {
    name: 'RefundRequest',
    data() {
        return {
            refundReasons: [],
            refundReason: [],
            tabPane: [
                {
                    id: 1,
                    name: '待申请退款',
                    orderlist: [],
                    total: 10, valuNum: 0,
                    page: 1,
                    getbug: false,
                    isIndeterminate: false,
                    limite: 10,
                    mag: '未申请退款环节数据详解：抖店退款订单并且已在分销采购中'
                },
                {
                    id: 2,
                    name: '已申请退款',
                    orderlist: [],
                    total: 10, valuNum: 0,
                    page: 1,
                    limite: 10,
                },
                {
                    id: 3,
                    name: '申请出错',
                    orderlist: [],
                    total: 10, valuNum: 0,
                    page: 1,
                    limite: 10,
                },
                {
                    id: 4,
                    name: '已拒绝退款',
                    orderlist: [],
                    total: 10, valuNum: 0,
                    page: 1,
                    limite: 10,
                },
                {
                    id: 5,
                    name: '退款成功',
                    orderlist: [],
                    total: 10, valuNum: 0,
                    page: 1,
                    limite: 10,
                },
            ],
            loading: true,
            activeName: '待申请退款',
            getTime: [
                {value: "1", label: "今天"},
                {value: "2", label: "昨天"},
                {value: "3", label: "本周"},
                {value: "4", label: "本月"},
            ],
            formInline: {
                son_order_id: '',
                dis_order_id: "",
                time_type: [],
                refund_id: '',
            },
            index: 0,
            TT: TT,
            refundVisible: false,
            lbb: lbb,
            syncVisible: false,
            syncData: {
                pickerValue: [],
                order_num: '',
            },
            loadingGet:false,
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            type: 1,
            activeRefund: 0,
            getRefundata: {
                order_id: '',
                order_son: {
                    product_name: "",
                    product_pic: ''
                },
                post_fee: "",
                order_spec: [],
                money: '',
            },
            getLIst: '',
            RefundRule: {
                applyReasonId: [
                    {required: true, message: '请选择退款原因', trigger: 'change'}
                ],
            },
            RefundList: {
                orderId: '',
                disputeRequest: 'refund',
                applyReasonId: "",
                applyPayment: '',
                vouchers: [],
                applyCarriage: '',
                description: '',
            },
            headers: {
                token: ""
            },
            refundsList: {
                refundReas: '',
            },
            getPays: [],
            getPayRefund: [],
            payVisible: false,
            fileList: [],
            fileListTao: [],
            Refund: [],
            rejectDate: {
                status: '',
                applyReason: '',
                rejectReason: '',
                refundGoods: false,
                refundPayment: 0
            },
            DetailVisible: false,
            typeRefund: 1,
            RefundListTaoT: {
                maxRefundFee: '',
                minRefundFee: '',
                apply_refund_text_id: '',
                biz_claim_type: '',
                goods_status: '',
                refund_fee: '',
                msg: '',
                biz_order_id: '',
                mustProof: '',
                leave_message_pics: []
            },
            RefundTaoRule: {
                biz_claim_type: [
                    {required: true, message: '请选择退款原因', trigger: 'change'}
                ],
                goods_status: [
                    {required: true, message: '请选择货物状态', trigger: 'change'}
                ]
            },
            getType: 0,
            refundTextWrapperVOList: []
        }
    },
    activated() {
        this.headers.token = localStorage.getItem('token')
        this.handleClick(0, 0)
    },
    methods: {
        handleClick(tab, get) {
            this.loading = true
            let index = 0;
            if (tab === 0) {
                index = get
            } else {

                index = Number(tab.index)
                this.getType = index
            }
            this.index = index
            let data = {}
            if (this.formInline.time_type === null) {
                data = {
                    status: index + 1,
                    begin_time: " ",
                    end_time: " ",
                    page: this.tabPane[index].page,
                    limit: this.tabPane[index].limite,
                    son_order_id: this.formInline.son_order_id,
                    dis_order_id: this.formInline.dis_order_id,
                    refund_id: this.formInline.refund_id
                }
            } else {
                data = {
                    status: index + 1,
                    begin_time: this.formInline.time_type[0]?.getTime(),
                    end_time: this.formInline.time_type[1]?.getTime(),
                    page: this.tabPane[index].page,
                    limit: this.tabPane[index].limite,
                    son_order_id: this.formInline.son_order_id,
                    dis_order_id: this.formInline.dis_order_id,
                    refund_id: this.formInline.refund_id
                }
            }
            refundCount().then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    for (let j = 0; j < this.tabPane.length; j++) {
                        if (res.data[i].id == this.tabPane[j].id) {
                            this.tabPane[j].valuNum = res.data[i].total
                        }
                    }
                }
            })
            refundIndexList(data).then(res => {
                if (res.code === 1) {
                    this.loading = false
                    let i = 0;
                    this.tabPane[index].orderlist = res.data.data.map(item => {
                        if (index === 0) {
                            if (this.getPayRefund.find(el => el.dis_order_id === item.dis_order_id)) {
                                i += 1;
                                item.check = true
                            } else {
                                item.check = false
                            }
                        }
                        return item
                    })
                    if (i === this.tabPane[index].orderlist.length) {
                        this.tabPane[index].getbug = true
                    } else if (i > 0 && i < this.tabPane[index].orderlist.length) {
                        this.tabPane[index].isIndeterminate = true
                    } else {
                        this.tabPane[index].getbug = false
                        this.tabPane[index].isIndeterminate = false
                    }
                    this.tabPane[index].valuNum = res.data.total
                    this.tabPane[index].total = res.data.total

                } else {
                    this.loading = false
                    this.$notify({
                        message: res.msg,
                        type: 'error'
                    })
                }
                this.loading = false

            })

        },
        syncOrder() {
            this.syncVisible = true
        },
        copyValue(id) {
            const that = this;
            this.$copyText(id).then(
                function () {
                    that.$message({
                        message: "复制成功!",
                        type: 'success'
                    });
                },
                function () {
                    that.$message({
                        message: "复制失败!",
                        type: 'error'
                    });
                }
            );
        },
        onSubform() {
            this.handleClick(0, this.index)
        },
        handleSizeChange(el, val) {
            const index = this.tabPane.findIndex(item => item.id == el)
            this.tabPane[index].limite = val
            this.handleClick(0, index)
        },
        handleCurrentChange(el, val) {
            const index = this.tabPane.findIndex(item => item.id == el)
            this.tabPane[index].page = val
            this.handleClick(0, index)
        },
        postSync() {
            let data = {}
            if (this.type === 1) {
                const create_time_start = this.syncData.pickerValue[0]?.getTime()
                const create_time_end = this.syncData.pickerValue[1]?.getTime()
                data = {
                    type: this.type,
                    create_time_start: create_time_start,
                    create_time_end: create_time_end,
                    order_num: ''
                }
            } else {
                data = {
                    type: this.type,
                    create_time_start: "",
                    create_time_end: "",
                    order_num: this.syncData.order_num
                }
            }
            refundSyn(data).then(res => {
                if (res.code === 1) {
                    this.syncVisible = false
                    this.$notify({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$notify({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        handleType(tab) {
            if (tab.index === "0") {
                this.type = 1
            } else {
                this.type = 2
            }
        },
        getRefund() {
            //1688提交申请
            if (this.typeRefund === 2) {
                if (this.RefundList.applyReasonId !== "") {
                    refundOrder(this.RefundList).then(res => {
                        if (res.code === 1) {
                            this.refundVisible = false
                            this.handleClick(0, 0)
                            this.$notify({
                                message: res.msg,
                                type: 'success'
                            })
                        } else {
                            this.$notify({
                                message: res.msg,
                                type: 'error'
                            })
                        }
                    })
                } else {
                    this.$message.error("请选择退款原因")
                }
            }//淘特提交申请
            else if (this.typeRefund === 3) {
                if (this.RefundListTaoT.apply_refund_text_id === "") {
                    this.$message.error("请选择退款原因")
                } else if (this.RefundListTaoT.mustProof === true && this.RefundListTaoT.leave_message_pics.length === 0) {
                    this.$message.error("请上凭证图片")
                } else {
                    const data = {
                        refund_fee: this.RefundListTaoT.refund_fee,
                        biz_claim_type: this.RefundListTaoT.biz_claim_type,
                        biz_order_id: this.RefundListTaoT.biz_order_id,
                        msg: this.RefundListTaoT.msg,
                        goods_status: this.RefundListTaoT.goods_status,
                        apply_refund_text_id: this.RefundListTaoT.apply_refund_text_id,
                        leave_message_pics: this.RefundListTaoT.leave_message_pics
                    }
                    taoTeRefund3(data).then(res => {
                        if (res.code === 1) {
                            this.refundVisible = false
                            this.handleClick(0, 0)
                            this.$notify({
                                message: res.msg,
                                type: 'success'
                            })
                        } else {
                            this.$notify({
                                message: res.msg,
                                type: 'error'
                            })
                        }
                    })
                }
            }
        },
        beforeUpload(file) {
            const isJPEG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isJPG = file.type === 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.warning('文件不符合规则（图片大小不超过 2M），请重新上传~')
                return false
            }
            if (!isJPG && !isPNG && !isJPEG) {
                this.$message.warning('上传图片只能是jpg，png，jpeg格式!')
                return false
            }
            return true;
        },
        beforeUploadTao(file) {
            const isJPEG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isJPG = file.type === 'image/jpg';
            const isLt2M = file.size / 1024 / 1024 < 1;
            if (!isLt2M) {
                this.$message.warning('文件不符合规则（图片大小不超过 1M），请重新上传~')
                return false
            }
            if (!isJPG && !isPNG && !isJPEG) {
                this.$message.warning('上传图片只能是jpg!')
                return false
            }
            return true;
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        handleExceedTao(files, fileListTao) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileListTao.length} 个文件`);
        },
        successFile(response) {
            if (response.code == 1) {
                this.$message.success('图片' + response.msg)
                this.RefundList.vouchers.push(response.data.imageDomain + "/" + response.data.imageRelativeUrl);
            } else {
                this.$message.warning('图片' + response.msg);
            }

        },
        successFileTao(response) {
            if (response.code == 1) {
                this.$message.success('图片' + response.msg)
                this.RefundListTaoT.leave_message_pics.push(response.data.fileUrl);
            } else {
                this.$message.warning('图片' + response.msg);
            }
        },
        RequestRefund(val) {
            this.typeRefund = val.order_type
            if (val.order_type == 2) {
                const data = {disOrderId: val.dis_order_id}
                getReason1688(data).then(res => {
                    if (res.code === 1) {
                        this.refundReason = res.data
                        this.refundVisible = true
                    } else {
                        this.$message.error(res.msg)
                    }
                })

            } else {
                const data = {disOrderId: val.dis_order_id}
                getRefundType(data).then(res => {
                    if (res.code === 1) {
                        this.refundReasons = res.data
                        this.refundVisible = true
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
            this.getRefundata = val;
            this.RefundListTaoT.refund_fee = this.getRefundata.money
            this.RefundListTaoT.biz_order_id = this.getRefundata.dis_order_id
            this.RefundList.applyPayment = (parseFloat(this.getRefundata.money) - this.getRefundata.post_fee).toFixed(2)
            this.RefundList.applyCarriage = this.getRefundata.post_fee
            this.RefundList.orderId = this.getRefundata.dis_order_id
        },
        getTwo(RefundListTaoT) {
            this.$refs[RefundListTaoT].validate((valid) => {
                if (valid) {
                    const data = {
                        biz_order_id: this.RefundListTaoT.biz_order_id,
                        biz_claim_type: this.RefundListTaoT.biz_claim_type,
                        goods_status: this.RefundListTaoT.goods_status
                    }
                    getRefundType2(data).then(res => {
                        if (res.code == 1) {
                            if (this.activeRefund++ > 2) this.activeRefund = 0;
                            this.refundTextWrapperVOList = res.data.refundTextWrapperVOList
                            this.RefundListTaoT.maxRefundFee = res.data.maxRefundFee
                            this.RefundListTaoT.minRefundFee = res.data.minRefundFee
                            this.RefundListTaoT.goods_status = res.data.goods_status
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    return false;
                }
            })
        },
        getRefundDetail(val) {
            this.DetailVisible = true
            const data = {
                son_order_id: val
            }
            refundDetail(data).then(res => {
                this.rejectDate = res.data || {}
            })
        },
        syncItem(id, num) {
            synDisOrder({order_id: id}).then(res => {
                if(res.code===1){
                    this.handleClick(0, num - 1)
                    this.$message.success(res.msg)
                }else {
                    this.$message.error(res.msg)
                }
            })

        },
        syncAll() {
            this.handleClick(0, this.getType)
        },
        getPayVisible(val) {
            this.getLIst = val
            if ((val === 1 && this.getPayRefund.length !== 0) || (val === 3 && this.getPays.length !== 0)) this.payVisible = true
            else this.$message.warning('请勾选要退款商品');
        },
        loopSelect(val, arr, getArr, get) {
            if (get === 1) {
                for (let i = 0; i < arr.length; i++) {
                    if (!getArr.find(el => el.dis_order_id === arr[i].dis_order_id)) {
                        getArr.push(arr[i])
                    }
                    this.tabPane[val - 1].orderlist.map(item => {
                        item.check = true
                    })
                }
            } else if (get === 2) {
                for (let i = 0; i < arr.length; i++) {
                    const index = getArr.findIndex(el => el.dis_order_id === arr[i].dis_order_id)
                    if (index !== -1) {
                        getArr.splice(index, 1)
                    }
                    this.tabPane[val - 1].orderlist.map(item => {
                        item.check = false
                    })
                }
            }
        },
        getAllCheck(val, check) {
            let arr = []
            arr = this.tabPane[val - 1].orderlist
            if (check) {
                this.tabPane[val - 1].getbug = true
                this.tabPane[val - 1].isIndeterminate = false
                if (val == 1) {
                    this.loopSelect(val, arr, this.getPayRefund, 1)
                } else {
                    this.loopSelect(val, arr, this.getPays, 1)
                }
            } else {
                if (val == 1) {
                    this.loopSelect(val, arr, this.getPayRefund, 2)
                } else {
                    this.loopSelect(val, arr, this.getPays, 2)
                }
            }
        },
        loopIsInd(id, arr) {
            let ik = 0
            for (let i = 0; i < this.tabPane[id - 1].orderlist.length; i++) {

                if (arr.find(el => el.dis_order_id === this.tabPane[id - 1].orderlist[i].dis_order_id)) {
                    ik += 1;
                }

            }
            return ik;
        },
        getIem(val, id, index, check) {
            if (id === 1) {
                this.getRide(val, id, index, check, this.getPayRefund)
            } else if (id === 3) {
                this.getRide(val, id, index, check, this.getPays)
            }

        },
        getRide(val, id, index, check, arr) {
            if (check) {
                const items = this.tabPane[id - 1].orderlist.find(el => {
                    return el.dis_order_id === val
                })
                arr.push(items);

            } else {
                this.tabPane[id - 1].orderlist[index].check = false
                const indexs = arr.findIndex(el => el.dis_order_id === this.tabPane[id - 1].orderlist[index].dis_order_id)
                arr.splice(indexs, 1)
            }

            const i = this.loopIsInd(id, arr)
            if (this.tabPane[id - 1].orderlist.length === i) {
                this.tabPane[id - 1].getbug = true
                this.tabPane[id - 1].isIndeterminate = false
            } else if (i > 0 && i <= this.tabPane[id - 1].orderlist.length) {
                this.tabPane[id - 1].isIndeterminate = true
            } else {
                this.tabPane[id - 1].getbug = false
                this.tabPane[id - 1].isIndeterminate = false
            }
        },
        subRenfund() {
            this.loadingGet=true
            if(this.refundsList.refundReas!==""){
                let getitem = []
                if (this.getLIst === 1) {
                    getitem = this.getPayRefund
                } else {
                    getitem = this.getPays
                }
                let dis_order_id = []
                for (let i = 0; i < getitem.length; i++) {
                    dis_order_id.push(getitem[i].dis_order_id)
                }
                const data = {
                    refund_des: this.refundsList.refundReas,
                    dis_order_id: dis_order_id
                }
                if(this.getLIst===1) this.getPayRefund=[]
                else this.getPays=[]
                manyRefund(data).then(res=>{
                    this.payVisible=false
                    this.loadingGet=false
                    this.handleClick(0,0)
                    if(res.code===1){
                        this.activeName="已申请退款"
                        this.handleClick(0,1)
                        this.$message.success(res.msg)
                    }else {
                        this.activeName="申请出错"
                        this.handleClick(0,2)
                        this.$message.error(res.msg)
                    }
                })
            }else {
                this.$message.warning('请填写退款原因')
            }



        }
    }
}
</script>
<template>
    <div class="bg-white rounded shadow">
        <div class=" mx-8 pt-6 box-border min-with">
            <div class="flex">
                <div>
                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                        <el-form-item label="创建时间">
                            <el-date-picker
                                v-model="formInline.time_type"
                                type="datetimerange"
                                :picker-options="pickerOptions"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                align="right">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="子订单编号">
                            <el-input v-model="formInline.son_order_id" placeholder="请输入子订单编号"></el-input>
                        </el-form-item>
                        <el-form-item label="采购订单编号">
                            <el-input v-model="formInline.dis_order_id" placeholder="请输入采购订单编号"></el-input>
                        </el-form-item>
                        <el-form-item label="退款编号">
                            <el-input v-model="formInline.refund_id" placeholder="请输入退款编号"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubform">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="flex ml-4">
                    <div class="wget" @click="syncOrder">
                        <i class="el-icon-refresh mb-2"></i>
                        <span>同步订单</span>
                    </div>
                    <div class="wget" @click="syncAll">
                        <i class="el-icon-thumb mb-2"></i>
                        <span>
                            刷新列表
                        </span>
                    </div>
                </div>
            </div>
            <div class="tabSt">
                <el-tabs>
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane :key="item.name" :name="item.name" v-for="item in tabPane">
                            <div slot="label">
                                {{ item.name }}({{ item.valuNum }})
                            </div>
                            <div class="mb-2">
                                <el-alert  type="warning" v-if="item.id==1" show-icon >
                                    {{ item.mag }}
                                </el-alert>
                            </div>
                            <div v-loading="loading" class="getscool getCursor">
<!--                                <div class="my-3">-->
<!--                                    <el-alert-->
<!--                                        v-if="item.orderlist.length==0"-->
<!--                                        title="暂无待处理数据"-->
<!--                                        type="info">-->
<!--                                    </el-alert>-->
<!--                                </div>-->
                                <el-empty v-if="item.orderlist.length===0"></el-empty>
                                <el-card shadow="hover" class=" enbox-w mb-4 " :key="itemE.son_order_id"
                                         v-for="itemE in item.orderlist">
                                    <div slot="header" class="header clearfix flex items-center  px-2 text-sm">
                                        <el-checkbox v-model="itemE.check" v-if="item.id===1||item.id===3"
                                                     @change="getIem(itemE.dis_order_id,item.id,index,itemE.check)"
                                                     class="pr-1.5 pl-4"></el-checkbox>
                                        <div class=" flex items-center">
                                            <span>订单号:</span>
                                            <span class="text-green-600 ">{{ itemE.son_order_id }}</span>
                                        </div>
                                        <div class=" flex items-center">
                                            <el-button type="text" @click="copyValue(itemE.son_order_id)"
                                                       size="mini">
                                                <i class="el-icon-document-copy"></i>
                                            </el-button>&nbsp;
                                        </div>
                                        <span class="pl-4" v-if="item.id!==1">申请时间：<span class="font-bold">
                                            {{ new Date(itemE.refund_log.createtime * 1000).toLocaleString() }}
                                        </span></span>
                                        <div class="pl-4">
                                            <el-button type="text"
                                                       @click="syncItem(itemE.order_id,item.id)">
                                                <i class="el-icon-refresh">刷新</i>
                                            </el-button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col ">
                                        <div v-if="itemE.refund_log.msg!==null">
                                            <div class="flex justify-between items-center  py-1 px-2 "
                                                 v-if="item.id==3||item.id==2||item.id==4">
                                                <el-alert
                                                    :title="itemE.refund_log.msg"
                                                    type="warning">
                                                </el-alert>
                                            </div>
                                        </div>

                                        <div class="flex justify-between  text-gray-500 py-1 px-2 ">
                                            <div class="flex justify-start">
                                                <div>
                                                    <el-image
                                                        class="rounded-md posiget"
                                                        style="width: 50px; height: 50px "
                                                        :src="itemE.order_son.product_pic"
                                                        fit="fill">
                                                        <div slot="error" class="image-slot">
                                                            <i class="el-icon-picture-outline"></i>
                                                        </div>
                                                    </el-image>
                                                </div>
                                                <div class=" flex flex-col mx-2">
                                                    <div class="flex WgET items-baseline">
                                                        <el-tooltip effect="light"
                                                                    :content="itemE.order_son.product_name"
                                                                    placement="top-start">
                                                            <span class="text-green-600 ">{{
                                                                    itemE.order_son.product_name
                                                                }}</span>
                                                        </el-tooltip>
                                                    </div>
                                                    <div class="flex">

                                                 <span :key="itemSpec.name" v-for="itemSpec in itemE.order_spec">
                                            {{ itemSpec.name }}：{{ itemSpec.value }};</span>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex flex-col justify-center">
                                                <span class="text-base font-bold">预计退款：￥{{ itemE.money }}</span>
                                                <span class="text-black  ml-2">x&nbsp;&nbsp;{{ itemE.num }}件</span>
                                            </div>
                                            <div class="flex flex-col justify-center">
                                                <el-button @click="getRefundDetail(itemE.son_order_id)" type="text"
                                                           v-if="item.id==2||item.id==4">
                                                    退款详情
                                                </el-button>
                                            </div>
                                        </div>
                                        <div
                                            class="backA text-sm text-gray-600  border-b flex justify-between items-center">
                                            <div class="flex px-1 my-3 ">
                                                <el-image
                                                    style="width: 23px; height: 23px "
                                                    :src="itemE.order_type==2?lbb:TT"
                                                >
                                                </el-image>
                                                <div class="pl-4">采购单号：<span class="text-green-600  ">{{
                                                        itemE.dis_order_id
                                                    }}</span></div>
                                                <div class="pl-4">采购数量：<span
                                                    class="text-green-700  ">{{ itemE.num }}</span>
                                                </div>
                                                <div class="pl-4">采购金额：<span
                                                    class="text-red-600   ">￥{{ itemE.money }}</span>
                                                </div>
                                                <div class="pl-4">创建时间：
                                                    {{
                                                        new Date(itemE.order_son.pay_time * 1000).toLocaleString()
                                                    }}
                                                </div>
                                            </div>
                                            <div @click="RequestRefund(itemE)" class="getButtons  mr-2"
                                                 v-if="item.id==1">
                                                申请退款
                                            </div>
                                            <div @click="RequestRefund(itemE)" class="getButtons  mr-2"
                                                 v-if="item.id==4||item.id==3">
                                                重新申请退款
                                            </div>
                                        </div>
                                    </div>

                                </el-card>

                            </div>
                            <div class="m-2 flex justify-center" v-if="item.orderlist.length!==0">
                                <div class="flex items-center" v-if="item.id===1||item.id===3">
                                    <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.getbug"
                                                 @change="(val)=>getAllCheck(item.id,val)">
                                        本页全选
                                    </el-checkbox>
                                    <div @click="getPayVisible(item.id)" class="cursor-pointer getSTY  ml-2"
                                    >
                                        批量申请退款 ({{
                                            item.id === 1 ? getPayRefund.length : item.id === 3 ? getPays.length : ''
                                        }})
                                    </div>
                                </div>
                                <el-pagination
                                    v-if="item.orderlist.length!==0"
                                    class="flex justify-center"
                                    @size-change="(val)=>handleSizeChange(item.id,val)"
                                    @current-change="(val)=>handleCurrentChange(item.id,val)"
                                    :current-page="item.currenpage"
                                    :page-sizes="[10, 20, 30, 40]"
                                    :page-size="100"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="item.total">
                                </el-pagination>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </el-tabs>
            </div>
            <el-dialog
                :visible.sync="payVisible"
                width="30%">
                <span slot="title">批量退款（共{{
                        getLIst === 1 ? getPayRefund.length : getPays.length
                    }}笔）</span>
                <el-form :inline="true" :model="refundsList" class="demo-form-inline">
                    <el-form-item
                        :rules="[
      { required: true, message: '请输入退款原因', trigger: 'blur' },
    ]"
                        label="退款原因">
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入退款原因"
                            v-model="refundsList.refundReas">
                        </el-input>
                    </el-form-item>
                </el-form>

                <span slot="footer" class="dialog-footer">
    <el-button @click="payVisible = false">取 消</el-button>
    <el-button :loading="loadingGet" type="primary" @click="subRenfund">批量申请退款</el-button>
  </span>
            </el-dialog>
            <el-dialog
                title="退款详情"
                width="30%"
                :visible.sync="DetailVisible">
                <div class="p-4 border border-blue-600 rounded-lg">
                    <el-form :model="rejectDate">
                        <el-form-item label="订单编号:">
                            {{ rejectDate.dis_order_id }}
                        </el-form-item>
                        <el-form-item label="退款金额（含运费）:">
                            {{ rejectDate.refundPayment }}
                        </el-form-item>
                        <el-form-item label="申请原因:">
                            {{ rejectDate.applyReason }}
                        </el-form-item>
                        <el-form-item label="退款状态:">
                            {{ rejectDate.status_text }}
                            <span v-if="rejectDate.rejectReason!==''">({{ rejectDate.rejectReason }})</span>
                        </el-form-item>

                    </el-form>
                </div>
            </el-dialog>
            <el-dialog
                title="平台数据迁移至此账号"
                :visible.sync="syncVisible"
                width="40%"
            >
                <el-tabs @tab-click="handleType" class="getTabs" tab-position="left" style="height: 200px;">
                    <el-tab-pane label="批量同步">
                        <div class="flex-col items-start wgetO p-4">
                            <span>时间段：</span>
                            <el-date-picker
                                class="w-3/4"
                                v-model="syncData.pickerValue"
                                type="datetimerange"
                                :picker-options="pickerOptions"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                align="right">
                            </el-date-picker>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="精准同步">
                        <div class="flex-col items-start wgetO p-4">
                            <span>退款申请编号：</span>
                            <el-input placeholder="请输入退款申请编号" v-model="syncData.order_num"></el-input>
                        </div>

                    </el-tab-pane>
                </el-tabs>
                <span slot="footer" class="dialog-footer">
    <el-button @click="syncVisible = false">取 消</el-button>
    <el-button type="primary" @click="postSync">确 定</el-button>
  </span>
            </el-dialog>
            <el-dialog
                :visible.sync="refundVisible"
                width="70%">
                <div>
                    <el-steps :active="activeRefund" finish-status="success" simple style="margin-top: 20px">
                        <el-step title="申请退款"></el-step>
                        <el-step v-if="typeRefund===3" title="填写原因"></el-step>
                        <el-step title="退款完毕"></el-step>
                    </el-steps>
                    <div v-if="activeRefund===0" class="flex border ">
                        <div class="p-4 border-r w-1/4 ">
                            <div class="text-lg text-black py-4">订单详情</div>
                            <div class="border-b border-t py-4 flex items-center">
                                <el-image style="width: 70px;height: 70px" :src="getRefundata.order_son.product_pic">
                                </el-image>
                                <div class="flex-col getWith mx-2">
                                    <div class="text-black">
                                        {{ getRefundata.order_son.product_name }}
                                    </div>
                                    <div>
                                    <span :key="itemE.name"
                                          v-for="itemE in getRefundata.order_spec">{{ itemE.name }}:{{ itemE.value }}
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <div class="py-4">
                                <div class="py-2"><span>订单编号</span>：<span
                                    class="text-green-700">{{ getRefundata.son_order_id }}</span></div>
                                <div class="py-2"><span>单价</span>：￥{{
                                        (parseFloat(getRefundata.money) - getRefundata.post_fee).toFixed(2)
                                    }}
                                </div>
                                <div class="py-2"><span>邮费</span>：￥{{ getRefundata.post_fee }}</div>
                                <div class="py-2">
                                    <span>商品总价</span>：￥
                                    {{ getRefundata.money }}
                                </div>
                            </div>
                        </div>
                        <div class="p-4 w-3/4 heightKO">
                            <div v-if="typeRefund===2">
                                <el-form :rules="RefundRule" ref="RefundList" label-position="right" :model="RefundList"
                                         class="demo-form-inline">
                                    <el-form-item label="服务类型：">
                                        <el-select v-model="RefundList.disputeRequest" placeholder="活动区域">
                                            <el-option label="退款" value="refund"></el-option>
                                            <el-option label="退款退货" value="returnRefund"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="退款原因：" prop="applyReasonId">
                                        <el-select v-model="RefundList.applyReasonId" placeholder="请选择退款原因">
                                            <el-option :key="items.id" v-for="items in refundReason"
                                                       :label="items.name"
                                                       :value="items.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="退款金额：" prop="applyPayment">
                                        <span>￥{{ RefundList.applyPayment }}</span>
                                    </el-form-item>
                                    <el-form-item label="退款运费金额：" prop="applyCarriage">
                                        <span>￥{{ RefundList.applyCarriage }}</span>
                                    </el-form-item>
                                    <el-form-item label="退款说明："
                                                  :rules="[
      { required: true, message: '退款说明不能为空'},
    ]">
                                        <el-input type="textarea" v-model="RefundList.description"
                                                  placeholder="退款说明">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="上传退款凭证：" prop="leave_message_pics">
                                        <el-upload
                                            :before-upload="beforeUpload"
                                            class="upload-demo"
                                            ref="upload"
                                            :limit="5"
                                            drag
                                            multiple
                                            :headers="headers"
                                            list-type="picture"
                                            :on-exceed="handleExceed"
                                            action="https://dyfxdfapi.niuniucrm.com//api/DisOrder/uploadReasonImg"
                                            :file-list="fileList"
                                            :on-success="successFile">
                                            <i class="el-icon-upload"></i>
                                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb
                                            </div>
                                        </el-upload>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="getRefund">提交申请</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div v-else>
                                <el-form :rules="RefundTaoRule" ref="RefundListTaoT" label-position="right"
                                         :model="RefundListTaoT"
                                         class="demo-form-inline">
                                    <el-form-item label="退款类型：" prop="biz_claim_type">
                                        <el-select v-model="RefundListTaoT.biz_claim_type" placeholder="请选择退款原因">
                                            <el-option :key="items.bizClaimType" v-for="items in refundReasons"
                                                       :label="items.refundTypeTitle"
                                                       :value="items.bizClaimType">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="货物状态：" prop="goods_status">
                                        <el-select v-model="RefundListTaoT.goods_status" placeholder="请选择货物状态">
                                            <el-option label="未收到货"
                                                       value="1"></el-option>
                                            <el-option label="已收到货"
                                                       value="2"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="退款金额：" prop="refund_fee">
                                        <span>￥{{ RefundListTaoT.refund_fee }}</span>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="getTwo('RefundListTaoT')">下一步</el-button>
                                    </el-form-item>

                                </el-form>
                            </div>
                        </div>
                    </div>
                    <div v-if="activeRefund===1" class="flex border ">
                        <div class="p-4 border-r w-1/4 ">
                            <div class="text-lg text-black py-4">订单详情</div>
                            <div class="border-b border-t py-4 flex items-center">
                                <el-image style="width: 70px;height: 70px" :src="getRefundata.order_son.product_pic">
                                </el-image>
                                <div class="flex-col getWith">
                                    <div class="text-black">
                                        {{ getRefundata.order_son.product_name }}
                                    </div>
                                    <div>
                                    <span :key="itemE.name"
                                          v-for="itemE in getRefundata.order_spec">{{ itemE.name }}:{{ itemE.value }}
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <div class="py-4">
                                <div class="py-2"><span>订单编号</span>：<span
                                    class="text-green-700">{{ getRefundata.son_order_id }}</span></div>
                                <div class="py-2"><span>单价</span>：￥{{
                                        (parseFloat(getRefundata.money) - getRefundata.post_fee).toFixed(2)
                                    }}
                                </div>
                                <div class="py-2"><span>邮费</span>：￥{{ getRefundata.post_fee }}</div>
                                <div class="py-2">
                                    <span>商品总价</span>：￥
                                    {{ getRefundata.money }}
                                </div>
                            </div>
                        </div>
                        <div class="p-4 w-3/4 heightKO">
                            <el-form :rules="RefundTaoRule" label-position="right" :model="RefundListTaoT">
                                <el-form-item label="退款类型：" prop="biz_claim_type">
                                    <el-select v-model="RefundListTaoT.biz_claim_type" disabled
                                               placeholder="请选择退款原因">
                                        <el-option :key="items.bizClaimType" v-for="items in refundReasons"
                                                   :label="items.refundTypeTitle"
                                                   :value="items.bizClaimType"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="货物状态：" prop="goods_status">
                                    <el-select v-model="RefundListTaoT.goods_status" disabled
                                               placeholder="请选择货物状态">
                                        <el-option label="未收到货"
                                                   value="0"></el-option>
                                        <el-option label="已收到货"
                                                   value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="apply_refund_text_id">
                                    <span slot="label">退款原因</span>
                                    <el-select clearable v-model="RefundListTaoT.apply_refund_text_id"
                                               placeholder="请选择退款原因">
                                        <el-option :key="items.textId" v-for="items in refundTextWrapperVOList"
                                                   :label="items.name"
                                                   :value="items.textId"></el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="退款金额：" prop="refund_fee">
                                    <span>￥{{ RefundListTaoT.maxRefundFee }}</span>（此处为最大退款金额额度）
                                </el-form-item>

                                <el-form-item v-if="activeRefund===1" prop="msg" label="退款说明：">
                                    <el-input type="textarea" v-model="RefundListTaoT.msg"
                                              placeholder="退款说明">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="上传退款凭证：">
                                    <el-upload
                                        :before-upload="beforeUploadTao"
                                        class="upload-demo"
                                        ref="upload"
                                        :limit="3"
                                        drag
                                        multiple
                                        :headers="headers"
                                        list-type="picture"
                                        :on-exceed="handleExceedTao"
                                        action="https://dyfxdfapi.niuniucrm.com//api/DisOrder/uploadImg"
                                        :file-list="fileListTao"
                                        :on-success="successFileTao">
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                        <div class="el-upload__tip" slot="tip">只能上传jpg文件，且不超过500kb
                                        </div>
                                    </el-upload>
                                </el-form-item>
                                <el-form-item v-if="activeRefund===1">
                                    <el-button type="primary" @click="getRefund">提交申请</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>

                </div>

            </el-dialog>
        </div>
    </div>
</template>
<style>
.min-with {
    min-width: 900px;
}

.getCursor {
    cursor: pointer;
}

.wgetO {
    border: 1px solid #3F9DFEFF;
    height: 200px;
    border-radius: 11px !important;
}

.getTabs .el-tabs__item.is-active {
    border-radius: 11px !important;
    color: #3F9DFEFF !important;
    border: 1px solid #3F9DFEFF !important;
    background-color: white !important;
}

.getTabs .el-tabs__nav-wrap::after {
    background-color: white !important;
}

.getTabs .el-tabs__active-bar {
    display: none;
}

.getButtons {
    color: #019943;
    border: 1px solid #019943;
    border-radius: 2.3rem;
    padding: 6px 3px;
}

.getButtons .el-button--text {
    color: #019943 !important;
}

.getscool {
    width: 100%;
    overflow: auto;
    max-height: 540px;
}

.backA {
    background-color: #f7f9fa;
    cursor: pointer;
}

.wget {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 80px;
    justify-content: center;
    flex-direction: column;
}

.wget i {
    font-weight: bolder;
    font-size: 30px;
}

.wget:hover {
    cursor: pointer;
    color: #019943 !important;
}
</style>