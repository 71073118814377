<script>
import {foldLineData, getTableData, sevenGoods, statistics} from "@/api/department";
import * as echarts from "echarts";

export default {
    name: 'ProfitReport',
    data() {
        return {
            dataBase: {
                orderNum: 0,
                orderMoney: 0,
                disOrderNum: 0,
                disOrderMoney: 0,
                refundOrderNum: 0,
                refundOrderMoney: 0
            },
            dataTimes: "",
            tableData: [],
            total: 0,
            page: 1,
            limite: 10,
            productList: [],
            myCharts: null,
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            start_time: '',
            end_time: ''
        }
    },
    activated() {
        this.getDatas()
    },
    methods: {
        getDatas() {
            const datas = {
                start_time: this.start_time === "" ? this.start_time : this.start_time.getTime(),
                end_time: this.end_time === "" ? this.end_time : this.end_time.getTime()
            }
            statistics(datas).then(res => {
                if (res.code === 1) {
                    this.dataBase = res.data
                } else {
                    this.$message.error(res.msg)
                }
            })
            const data = {
                page: this.page,
                limit: this.limite
            }
            getTableData(data).then(res => {
                if (res.code === 1) {
                    this.tableData = res.data.data
                    this.total = res.tatal
                } else {
                    this.$message.error(res.msg)
                }
            })
            sevenGoods(datas).then(res => {
                if (res.code === 1) {
                    this.productList = res.data
                } else {
                    this.$message.error(res.msg)
                }
            })
            if (this.myCharts) {
                this.myCharts.dispose();//先销毁再创建
            }
            this.myCharts = null;
            echarts.dispose(document.getElementById('main'))
            this.myCharts = echarts.init(document.getElementById('main'))
            foldLineData(datas).then(res => {
                const option = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['采购订单数', '采购金额', '销售金额', '利润']
                    },
                    grid: {
                        left: '3%',
                        right: '7%',
                        bottom: '10%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    dataZoom: [
                        {
                            type: 'slider',
                            realtime: false,
                            start: 0,
                            bottom: 10,
                            end: 100,
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: res.data.time
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '采购订单数',
                            type: 'line',
                            data: res.data.order_num
                        },
                        {
                            name: '采购金额',
                            type: 'line',
                            data: res.data.dis_money
                        },
                        {
                            name: '销售金额',
                            type: 'line',
                            data: res.data.sale_money
                        },
                        {
                            name: '利润',
                            type: 'line',
                            data: res.data.profit_money
                        },
                    ]
                };
                this.myCharts.setOption(option);
                window.addEventListener('resize', () => {
                    this.myCharts.resize()
                })
            })
        },
        getTime() {
            this.start_time = this.dataTimes[0]
            this.end_time = this.dataTimes[1]
            this.getDatas()
        }
    }
}
</script>
<template>
    <div class="minW">
        <div class="px-8 pb-2 pt-4 bg-white shadow">
            <div class="my-1 mb-3">
                <span>选择日期：</span>
                <el-date-picker
                    v-model="dataTimes"
                    type="daterange"
                    align="right"
                    unlink-panels
                    @change="getTime"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
                <span class=" ml-3 text-green-700">【默认展示今日数据】</span>
            </div>
            <ul class="flex justify-between">
                <li class="border rounded-xl p-4 getminW">
                    <div class="flex pb-3 items-end">
                        <span class="text-4xl text-red-500">{{ dataBase.orderMoney }}</span>
                        <span class="text-gray-500 ml-3">订单金额</span>
                    </div>
                    <div class="font-semibold">订单数：{{ dataBase.orderNum }}</div>
                </li>
                <li class="border rounded-xl p-4 getminW">
                    <div class="flex pb-3 items-end">
                        <span class="text-4xl text-red-500">
                            {{ dataBase.refundOrderMoney }}
                        </span>
                        <span class="text-gray-500 ml-3">退款金额</span>
                    </div>
                    <div class="font-semibold">退款数：{{ dataBase.refundOrderNum }}</div>
                </li>
                <li class="border rounded-xl p-4 getminW">
                    <div class="flex pb-3 items-end">
                        <span
                            class="text-4xl text-red-500">{{
                                Math.min(dataBase.orderMoney - dataBase.disOrderMoney)
                            }}</span>
                        <span class="text-gray-500 ml-3">利润</span>
                    </div>
                    <div class="font-semibold">利润百分比：{{ dataBase.profit_margin }}</div>
                </li>
            </ul>
        </div>
        <div class="px-8 py-2 bg-white shadow flex justify-start ">
            <div class="  rounded   minWs">
                <div class="font-extrabold  mb-2">商品销量 Top7</div>
                <div v-if="productList.length===0">
                    <el-empty description="暂无数据"></el-empty>
                </div>
                <ul class="getminH">
                    <li :key="item.product_id" v-for="item in productList"
                        class=" border rounded my-1 p-2 flex text-gray-500  ">
                        <el-image
                            style="width: 50px ;height: 50px"
                            :src="item.img">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <div class="flex-col flex ml-2">
                            <span>{{ item.name?item.name:"暂无商品标题" }}</span>
                            <div class="flex font-bold text-base">
                                <span>总销量：<span class="text-red-500">{{ item.total_item_num }}</span></span>
                                <span class="ml-1 ">销售总额：<span class="text-red-500">{{
                                        item.total_amount
                                    }}</span></span>
                            </div>
                        </div>

                    </li>
                </ul>
            </div>
            <!--            <div class="w-6/12 ml-4 ">-->
            <div id="main" class="flex-1"></div>
            <!--            </div>-->
        </div>
        <div class="px-8 py-2 bg-white shadow ">
            <div class="border rounded ">
                <el-table
                    max-height="300"
                    :data="tableData"
                    style="width: 100%">
                    <template slot="empty">
                        <el-empty></el-empty>
                    </template>
                    <el-table-column
                        align="center"
                        prop="order_day"
                        label="统计日期">
                    </el-table-column>
                    <el-table-column
                        prop="shop_name"
                        label="店铺名称">
                    </el-table-column>
                    <el-table-column
                        prop="total_amount"
                        label="订单总金额">
                    </el-table-column>
                    <el-table-column
                        prop="main_order_count"
                        label="主订单数">
                    </el-table-column>
                    <el-table-column
                        prop="son_order_count"
                        label="子订单数">
                    </el-table-column>
                    <el-table-column
                        prop="dis_total_amount"
                        label="采购订单金额">
                    </el-table-column>
                    <el-table-column
                        prop="dis_main_order_count"
                        label="采购主订单数">
                    </el-table-column>
                    <el-table-column
                        prop="dis_son_order_count"
                        label="采购子订单数">
                    </el-table-column>
                </el-table>
            </div>

        </div>
    </div>
</template>
<style>
.minWs {
    height: 400px;
    min-width: 500px;
}

#main {
    width: 100%;
    height: 400px;
}

.minW {
    min-width: 700px;
    width: 100%;
}

.getminH {
    overflow-y: auto;
    height: 350px;
}

.getminW {
    width: 32%;
}
</style>