<template>
    <div id="app">
        <router-view/>
    </div>


</template>
<script>
localStorage.setItem('url','https://ksfxdfapi.niuniucrm.com/')
</script>
<style>

#app,html , body {
    width: 100%;
    font-family: iconfont;
    padding: 0;
    margin: 0;
    height: 100%;
}
#nprogress .bar{
    background: #019943 !important;
}
</style>
